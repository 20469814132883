import { lazy } from "react";

export const FarmItem = lazy(() =>
  import("./FarmItem" /* webpackChunkName: "item detail" */)
);
export const Farm = lazy(() =>
  import("./Farm" /* webpackChunkName: "farm detail" */)
);
export const UserProfile = lazy(() =>
  import("./user-profile" /* webpackChunkName: "user profile" */)
);
export const ResetPassword = lazy(() => import("./reset-password"));
export const QuickRFQ = lazy(() => import("./quick-rfq"));
export const Sitemap = lazy(() => import("./sitemap"));
export const Category = lazy(() =>
  import("./category" /* webpackChunkName: "category" */)
);
export const Login = lazy(() => import("./Login"));
export const SendInquiry = lazy(() => import("./send-inquiry"));
export const Register = lazy(() =>
  import("./register" /* webpackChunkName: "register" */)
);
export const ActiveAccount = lazy(() => import("./active-account"));
export const Products = lazy(() => import("./products"));
export const Suppliers = lazy(() => import("./suppliers"));
export const Payment = lazy(() => import("./payment"));
export const PaymentPending = lazy(() =>
  import("./payment-pending" /* webpackChunkName: "payment-pending" */)
);
export const ActiveAccountBuyer = lazy(() => import("./active-account-buyer"));
export const Unsubscribe = lazy(() => import("./unsubscribe"));
export const JoinCampaign = lazy(() => import("./join-campaign"));
export const NewArrivals = lazy(() =>
  import("./new-arrivals" /* webpackChunkName: "new arrivals" */)
);

export const FreshdiChoice = lazy(() =>
  import("./freshdi-choice" /* webpackChunkName: "freshdi choice" */)
);
export const Home = lazy(() =>
  import("Pages/home" /* webpackChunkName: "home" */)
);
export const PageNotFound = lazy(() =>
  import("Pages/PageNotFound" /* webpackChunkName: "pagenotpage" */)
);
export const Pricing = lazy(() =>
  import("Pages/pricing" /* webpackChunkName: "pricing" */)
);

export const ContactUs = lazy(() =>
  import("Pages/contact-us" /* webpackChunkName: "pricing" */)
);

export const TermOfUse = lazy(() =>
  import("Pages/term-of-use" /* webpackChunkName: "term of use" */)
);

export const PrivacyPolicy = lazy(() =>
  import("Pages/privacy-policy" /* webpackChunkName: "privacy-policy" */)
);

export const IntellectualPropertyPolicy = lazy(() =>
  import(
    "Pages/intellectual-property-policy" /* webpackChunkName: "intellectual-property-policy" */
  )
);

export const Disclaimers = lazy(() =>
  import("Pages/disclaimers" /* webpackChunkName: "disclaimers" */)
);
