export const BREAKPOINT_VALUES = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1440,
  xl: 1530,
};

export const AUTO_PLAY_ITEM_TIME = 5000;
export const AUTO_PLAY_BANNER_TIME = 3000;

export const MENU_WIDTH = {
  expanded: 270,
  collaped: 52,
  collapedMobile: 52,
};

export const MAX_NOTI_CHAR_QLT = 99;

export const MAX_CHAT_CHARACTER_LEN = 500;

export const PAYMENT_PAGES = {
  preview: "preview",
  detail: "detail",
  statusSuccessful: "status-successful",
  statusFail: "status-fail",
};

export const LISTING_FILTER_WIDTH = 200;

export const COLORS = {
  green: {
    25: "#EDFFF4",
    50: "#e3ffee",
    500: "#019c3c",
    600: "#008934",
    700: "#008934",
    800: "#025020",
  },
  brand: {
    25: "#edfff4",
    500: "#019c3c",
    700: "#027b30",
  },
  gray: {
    50: "#f9fafb",
    100: "#f2f4f7",
    200: "#EEEEEE",
    300: "#d0d5dd",
    500: "#667085",
    600: "#475467",
    700: "#344054",
    800: "#1d2939",
    900: "#101828",
  },
  yellow: {
    25: "#fffaeb",
    100: "#fef0c7",
    400: "#FDB022",
    500: "#f79009",
    600: "#DC6803",
  },
  blue: {
    700: "#026aa2",
  },
  error: {
    50: "#fef3f2",
    500: "#f04438",
    700: "#b42318",
  },
  app: {
    bg: "#f9fafb",
  },
  warning: {
    900: "#7A2E0E",
  },
};

export const LISTING_CARD_LIMIT = {
  search: 32,
  category: 32,
  newArrivals: 30,
  freshdiChoice: 30,
};

export const SCROLL_TOP_POSITIONS = {
  desktopHeader: 72,
  mobileHeader: 56,
};

export const ALLOWED_FILE_TYPES = [
  "image/jpeg",
  "image/png",
  "image/jpg",
  "application/pdf",
  "application/msword",
  "application/vnd.ms-excel",
];

export const HOME_SLIDE_BREAKPOINTS = {
  300: {
    slidesPerView: 1,
    spaceBetween: 8,
  },
  640: {
    slidesPerView: 2,
    spaceBetween: 8,
  },
  960: {
    slidesPerView: 3,
    spaceBetween: 8,
  },
};

export const MAX_FILE_SIZE_MB = 10;

export const CHAT_LAYOUT = {
  chatContainerWidth: 300,
  expandedChatContainerWidth: 724,
};
