import { ConversationType, MobileChatDetailType } from "types/chat";
import { Client, Conversation } from "@twilio/conversations";
import { CHAT_ACTIONS } from "redux/actions/chat";

const initialState: {
  twlToken: string;
  twlIdentify: string;
  conversations: ConversationType[];
  chatClient?: Client;
  openChatContainer?: {
    open: boolean;
    conversation?: Conversation;
  };
  chatList: Conversation[];
  reloadHeaderChat?: boolean;
  mobileChatDetail: MobileChatDetailType;
} = {
  twlToken: "",
  twlIdentify: "",
  conversations: [],
  chatClient: undefined,
  openChatContainer: {
    open: false,
  },
  chatList: [],
  reloadHeaderChat: false,
  mobileChatDetail: {
    open: false,
    conversationId: undefined,
  },
};

const chatReducer = (
  state = initialState,
  action: { payload: any; type: string }
) => {
  switch (action.type) {
    case CHAT_ACTIONS.setTwlToken: {
      return {
        ...state,
        twlToken: action.payload,
      };
    }

    case CHAT_ACTIONS.setTwlIdentify: {
      return {
        ...state,
        twlIdentify: action.payload,
      };
    }

    case CHAT_ACTIONS.setChatClient: {
      return {
        ...state,
        chatClient: action.payload,
      };
    }

    case CHAT_ACTIONS.addConverstations: {
      return {
        ...state,
        conversations: [...state.conversations, ...(action.payload || [])],
      };
    }

    case CHAT_ACTIONS.setConverstations: {
      return {
        ...state,
        conversations: action.payload || [],
      };
    }

    case CHAT_ACTIONS.setChatList: {
      return {
        ...state,
        chatList: action.payload,
      };
    }

    case CHAT_ACTIONS.reloadHeaderChat: {
      return {
        ...state,
        reloadHeaderChat: !state.reloadHeaderChat,
      };
    }

    case CHAT_ACTIONS.setMobileChatDetail: {
      return {
        ...state,
        mobileChatDetail: action.payload,
      };
    }

    // Open chat container
    case CHAT_ACTIONS.openChatContainer: {
      return {
        ...state,
        openChatContainer: {
          open: true,
          conversation: action.payload,
        },
      };
    }

    // Close chat container
    case CHAT_ACTIONS.closeChatContainer: {
      return {
        ...state,
        openChatContainer: {
          open: false,
        },
      };
    }

    default:
      return state;
  }
};

export default chatReducer;
